<template>
  <div>
    <el-table
      :row-key="record => record.uuid"
      :data="items"
      :loading="loading"

    >
      <el-table-column label="版本信息">
        <template slot-scope="scope">{{scope.row.versionTag}}
          <template v-if="scope.row.isDefault == 1"><el-tag effect="plain">默认版本</el-tag></template>
        </template>
      </el-table-column>
      <el-table-column label="更新内容" prop="description"></el-table-column>
      <el-table-column label="md5" prop="md5"></el-table-column>
      <el-table-column label="上传时间">
        <template slot-scope="scope">{{new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd')}}</template>
      </el-table-column>
      <el-table-column label="操作">
        <p slot-scope="scope">
          <el-button type="text" size="small" v-if="scope.row.isDefault !== 1"> 设为默认版本</el-button>
          <span type="link" size="small" v-else> - </span>
        </p>
      </el-table-column>
    </el-table>

    <el-pagination @current-change="handleNext"
                   class="pagination"
                   :current-page="pagination.curPage"
                   :page-size="pagination.limit"
                   layout="total, prev, pager, next, jumper"
                   :total="pagination.total"></el-pagination>
  </div>
</template>

<script>
  import {productVersionUnion, productVersionList} from '@/api/system/product'

  export default {
    name: 'product-detail-version',
    components: {},
    mounted: function () {
      this.loadProduct(this.productUuid)
    },
    props: {
      productUuid: {
        type: String,
        required: true
      }
    },
    data: function () {
      return {
        items: [],
        loading: false,
        pagination: {
          start: 0,
          curPage: 1,
          limit: 10,
          total: 1,
        },
      }
    },
    methods: {
      handleNext(page) {
        this.pagination.start = (page - 1) * this.pagination.limit
        let pagination = Object.assign({}, this.pagination)
        pagination.uuid = this.productUuid
        pagination.onChange = null
        productVersionList(pagination).then(res => {
          this.items = res.data || []

        })
      },
      loadProduct(uuid) {
        productVersionUnion({uuid: uuid}).then(res => {
          this.items = res.data.list || []
          this.pagination.total = parseInt(res.data.total, 10)
        })
      }
    },
    watch: {
      productUUID: { //深度监听，可监听到对象、数组的变化
        handler(newV) {
          this.loadProduct(newV)
        },
        deep: true
      },
    }
  }
</script>

<style>

</style>
