<template>
  <div class="detail">
   <step-container :name="product.name">
     <template slot="step">
       <el-button type="text" class="container-btn">
         <i class="el-icon-edit"/>
         修改名称
       </el-button>

       <el-button type="text" class="container-btn" @click="handleAddVersion">
         <i class="el-icon-upload"/>
         上传版本
       </el-button>

       <el-button type="text" class="container-btn" @click="handleAddVersion">
         <i class="el-icon-folder-add"/>
         关联模型
       </el-button>
     </template>
   </step-container>
    <div class="detail-container">
      <div class="detail-container-item">
        <div class="item">
          <p>模型名称: {{product.name}}</p>
          <p>模型Key: {{product.productKey}}</p>
          <p>模型密钥: {{product.productSecret}}</p>
          <p>设备数量: {{product.devices | I1000}}</p>
          <p>模型备注: {{product.remark}}</p>
          <p>创建时间: {{new Date(product.createdAt * 1000).Format('yyyy-mm-dd hh:MM:ss')}}</p>
        </div>
        <el-tabs default-active-key="1" class="detail-container-table">
          <el-tab-pane key="1" label="版本信息">
            <detail-version :product-uuid="$route.params.uuid"></detail-version>
          </el-tab-pane>
          <el-tab-pane key="2" label="相关设备">
            <detail-device :product-uuid="$route.params.uuid"></detail-device>
          </el-tab-pane>
          <el-tab-pane key="3" label="模型定义">
          <div>
            <el-button type="text" @click="handleAddItem">添加变量</el-button>
            <el-button type="text" style="margin-left: 16px;" @click="handleSaveProfile">保存配置</el-button>
          </div>
            <recycle-item :nodes="params" :depth="0" :edit="true"></recycle-item>
          </el-tab-pane>
          <el-tab-pane key="4" label="相关产品">
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <el-dialog class="dialog"
               :visible.sync="addVersionVisible"
             :width="'560px'" :height="'240px'" title="上传版本">
      <div class="version-dialog">
        <upload :url.sync="version.upgrade" :fileShowType="4" :isMd5="true" class="upload"
                @upload-success="fileUploadSuccess"></upload>
        <div class="version-dialog-body clearfix">
          <el-form class="base-info" label-width="100px">
            <el-form-item label="版本信息">
              <el-input v-product="version.version" disabled></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-form class="ctrl-info" label-width="100px">
          <el-form-item label="版本说明">
            <el-input v-product="version.description" type="textarea" :rows="3"></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-commit-btn">
          <el-button type="primary" @click="handleVersionCreate">提交</el-button>
          <el-button  @click="addVersionVisible = false">取消</el-button>
        </div>
      </div>

    </el-dialog>

  </div>
</template>

<script>
  import {productDetail} from '@/api/system/product'
  import DetailVersion from './detail_version'
  import DetailDevice from './detail_device'
  import Upload from '@/components/Upload'
  import {checkObj} from "@/utils/check"
  import RecycleItem from "@/components/RecycleItem/index";

  export default {
    name: '',
    components: {
      RecycleItem,
      DetailVersion,
      DetailDevice,
      Upload
    },
    mounted: function () {
      productDetail({uuid: this.$route.params.uuid}).then(res => {
        this.product = res.data
      })
    },
    data: function () {
      return {
        addVersionVisible: false,
        params:[],
        version: {
          productUuid: '',
          version: "",
          description: '',
          upgrade: '',
          md5: ''
        },
        product: {
          name: '',
          unitTxt: 'g',
        },
      }
    },
    methods: {
      fileUploadSuccess(data) {
        let version = data.name.substr(0, data.name.lastIndexOf("."))
        this.version.md5 = data.md5
        this.version.upgrade = data.url
        this.version.version = version
      },
      handleAddItem() {
        this.params.push({
          name: '',
          variable: '',
          type: 1,
          min: 0,
          max: 0,
          step: 0,
          default: true,
          unitName: '',
          nodes: []
        })
      },
      handleSaveProfile() {
        if (this.items.length === 0) {
          this.$message.error('请输入模型参数')
          return
        }
        if (!this.isEdit) {
          this.$message.success('更新成功')
          return
        }
        // productConfUpdate({uuid: this.pagination.productUuid, nodes: this.items}).then(res => {
        //   this.$message.success('更新成功')
        //   this.isEdit = false
        //   return
        // })
      },
      handleAddVersion() {
        this.version = {
          productUuid: this.product.uuid,
          version: "",
          description: '',
          upgrade: '',
          md5: ''
        }
        this.addVersionVisible = true
      },

      handleVersionCreate() {
        let status = checkObj(this.version, [
          {value: 'productUuid', message: '请选取模型'},
          {value: 'md5', message: '请上传文件'},
          {value: 'upgrade', message: '请上传文件'},
          {value: 'description', message: '请填写描述'}
        ])
        if (!status.status) {
          this.$message.warning(status.message)
          return
        }
        // versionCreate(this.version).then(res => {
        //   this.loadVersion()
        //   this.addVersionVisible = false
        //   this.$message.success('上传成功')
        // })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .dialog-commit-btn{
    text-align: center;
  }

  .version-dialog{
    .upload{
      margin-bottom: $small-space;
    }
  }
  .detail {


    &-title-container {
      @include container();
      background-color: $color-white;

      .tip-container {
        text-align: right;

        .tip {
          width: 124px;
        }
      }


      .remark {
        margin: $small-space 0;
        @include font-little();
        color: $remark-text-color;
      }

      .container {
        display: flex;
        justify-items: center;

        &-name {
          &-text {
            @include font-large-s();
          }

          &-unit {
            display: inline-block;
            margin: 0 $small-space;
            @include font-little();
          }
        }

        &-btn {
          color: $color-base;
          padding-left: 0;
        }

        &-btn:hover {
          color: $theme-color;
          font-weight: 500;
        }

        &-back {
          padding-left: $small-space;

        }
      }

    }

    .image {
      text-align: center;

      img {
        max-width: 240px;
        max-height: 180px;
      }
    }

    &-container {
      margin: $middle-space;
      margin-bottom: 0;
      background-color: $color-white;

      &-table {
        margin-top: $large-space;
      }

      &-item {
        padding: $container-padding;
        //width: 80%;
        min-width: 640px;
        margin: auto;
        min-height: 592px;

        .item {
          margin-top: $middle-space;

          p {
            margin-bottom: $small-space;
          }
        }
      }
    }
  }
</style>
