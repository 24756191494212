<template>
  <div>
    <el-table
      :row-key="record => record.uuid"
      :data="items"
      :loading="loading"
    >
      <el-table-column label="产品信息">
        <div slot-scope="scope">
          <p>设备编号:{{scope.row.uuid}}</p>
          <p>设备名称:{{scope.row.name}}</p>
          <p>注册时间:{{new Date(scope.row.registerAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</p>
        </div>
      </el-table-column>

      <el-table-column label="经销商名称" prop="orgName"></el-table-column>

      <el-table-column label="连接信息">
        <div  slot-scope="scope">
          <p>imei :{{scope.row.imei}}</p>
          <p>sim :{{scope.row.sim}}</p>
          <p>连接方式 :{{scope.row.connectType}}</p>
        </div>
      </el-table-column>

      <el-table-column label="设备状态">
        <div  slot-scope="scope">
          <p>连接状态 :<span :style="{color: DEVICE_STATUS[scope.row.connectStatus].color}"> {{DEVICE_STATUS[scope.row.connectStatus].name}} </span>
          </p>
          <p>运行状态 :<span :style="{color: RUN_STATUS[scope.row.runStatus].color}"> {{RUN_STATUS[scope.row.runStatus].name}} </span>
          </p>
        </div>
      </el-table-column>
      <el-table-column label="最近上线时间">
        <div  slot-scope="scope">
          <p>{{new Date(scope.row.lastLoginAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</p>
        </div>
      </el-table-column>

    </el-table>
    <el-pagination @current-change="handleNext"
                   class="pagination"
                   :current-page="pagination.curPage"
                   :page-size="pagination.limit"
                   layout="total, prev, pager, next, jumper"
                   :total="pagination.total"></el-pagination>
  </div>
</template>

<script>
  import {systemDeviceUnion, systemDeviceList} from '@/api/system/device'

  const LOCK_STATUS = [
    '未锁定',
    '锁定',
  ]

  const DEVICE_STATUS = [
    {name: '未初始化', color: ''},
    {name: '在线', color: '#00CC00'},
    {name: '离线', color: '#FF6600'},
  ]

  const RUN_STATUS = [
    {name: '未初始化', color: ''},
    {name: '正常', color: '#00CC00'},
    {name: '维修', color: '#FF6600'},
  ]

  export default {
    name: 'product-detail-version',
    components: {},
    mounted: function () {
      this.loadProduct(this.productUuid)
    },
    props: {
      productUuid: {
        type: String,
        required: true
      }
    },
    data: function () {
      return {
        LOCK_STATUS,
        DEVICE_STATUS,
        RUN_STATUS,
        items: [],
        loading: false,
        pagination: {
          start: 0,
          curPage: 1,
          limit: 10,
          total: 1,
        },
      }
    },
    methods: {
      handleNext(page) {
        this.pagination.start = (page - 1) * this.pagination.limit
        let pagination = Object.assign({}, this.pagination)
        pagination.productUuid = this.productUuid
        pagination.onChange = null
        systemDeviceList(pagination).then(res => {
          this.items = res.data || []

        })
      },
      loadProduct(uuid) {
        systemDeviceUnion({productUuid: uuid}).then(res => {
          this.items = res.data.list || []
          this.pagination.total = parseInt(res.data.total, 10)
        })
      }
    },
    watch: {
      productUUID: { //深度监听，可监听到对象、数组的变化
        handler(newV) {
          this.loadProduct(newV)
        },
        deep: true
      },
    }
  }
</script>


<style>

</style>
