import request from '@/utils/request';

export function systemDeviceUnion(params) {
  return request({
    url: `/v1/equipment/device/systemUnion`,
    method: 'GET',
    params
  })
}

export function systemDeviceList(params) {
  return request({
    url: `/v1/equipment/device/systemList`,
    method: 'GET',
    params
  })
}
